<template>
    <div v-loading.fullscreen.lock="loading">
        <stock-count-search-bar-component
            :AllClient="fAllClient"
            @search="get_stock_count"
        ></stock-count-search-bar-component>
        <common-table-component
            :col_arr="fcol_arr"
            :table_data="ftable_data"
        ></common-table-component>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="12">库存现有总重量：<span style="color: #2828FF;">{{sum_weight | diy_sum_weight}}</span></el-col>
                <el-col :span="12">库存现有总托盘数：<span style="color: #2828FF;">{{sum_tray_num | diy_sum_tray_num}}</span></el-col>
            </el-row>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_stock_count"
        ></common-page-component>
    </div>
</template>

<script>

import { stock_count_request} from '@/network/WareHouse/stock.js'
import { client_list_request} from '@/network/list.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import StockCountSearchBarComponent from '@/components/WareHouse/Stock/StockCountSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'StockCountView',
    data(){
        return {
            loading: true,
            cond: {
                name: '',
                client_id: '',
                storeroom_id: '',
                localtion_id: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'with_client_name',
                    label: '所属客户',
                    minWidth: '280px'
                },
                {
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '300px'
                },
                {
                    prop: 'specifications',
                    label: '规格',
                    minWidth: '120px'
                },
                {
                    prop: 'count_weight',
                    label: '总重量(KG)',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['count_weight']))
                    }
                },
                {
                    prop: 'count_tray_num',
                    label: '总托盘数',
                    minWidth: '100px',
                    editdata(row) {
                        return row['count_tray_num'] + '个'
                    }
                }
            ],
            fAllClient: [],
            sum_weight: 0,
            sum_tray_num: 0
        }
    },
    computed:{},
    methods:{
        get_stock_count(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.storeroom_id = param.storeroom_id === undefined ? this.cond.storeroom_id : param.storeroom_id[param.storeroom_id.length - 1]
            this.cond.localtion_id = param.localtion_id ?? this.cond.localtion_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            stock_count_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                        this.sum_weight = s.result.count_weight
                        this.sum_tray_num = s.result.count_tray_num
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_client() {
            client_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{
        diy_sum_weight(e) {
            return thousandBitSeparator(fomatFloat(e)) + 'KG'
        },
        diy_sum_tray_num(e) {
            return thousandBitSeparator(e) + '个'
        }
    },
    props:{},
    created(){
        this.get_all_client()
        this.get_stock_count()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        StockCountSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>